window.onload = function(){
  //Click a
  const links = document.querySelectorAll("body a");

  for (const link of links) {
    if(link.getAttribute("data-ref") == "scroll" || link.getAttribute("data-ref") == "scroll-form") {
      link.addEventListener("click", clickHandler);
    }
    else {
      link.addEventListener("click", clickLink);
    }
  }

  function clickHandler(e) {
    e.preventDefault();

    const data = this.getAttribute("data-ref");
    //console.log(data);
    const href = this.getAttribute("href");
    //console.log(href);

    if(href == "#id-about" && window.location.pathname != "/") {
      location.href = window.location.protocol+'//'+window.location.host+'/'+'#id-about';
    }
    else {
      if (data == "scroll-form") {
        var selectServices = document.getElementById("service");
        var option = this.getAttribute("data-option");

        selectServices.value = option;
      }
      const offsetTop = document.querySelector(href).offsetTop;
      scroll({
        top: offsetTop,
        behavior: "smooth"
      });
    }
  }

  function clickLink(e) {
    e.preventDefault();

    const data = this.getAttribute("data-ref");

    const href = this.getAttribute("href");
    let url = href.split("#").join('');

    if(data == "link") {
      location.href = url;
    }

    if(data == "link-open") {
      let win = window.open(url, '_blank');
      win.focus();
      location.reload();
    }

    if(data == "print-pdf") {
      let win = window.open(url, '_blank');
      win.focus();
    }
    else {
      if (this.getAttribute('target') != null || this.getAttribute('target') != undefined) {
        let win = window.open(url, '_blank');
        win.focus();
      }
      else {
        location.href = href;
      }
    }
  }
}

// Burger menus
document.addEventListener("DOMContentLoaded", function () {
	// open
	const burger = document.querySelectorAll(".navbar-burger");
	const menu = document.querySelectorAll(".navbar-menu");

	if (burger.length && menu.length) {
		for (var i = 0; i < burger.length; i++) {
			burger[i].addEventListener("click", function () {
				for (var j = 0; j < menu.length; j++) {
					menu[j].classList.toggle("hidden");
				}
			});
		}
	}

	// close
	const close = document.querySelectorAll(".navbar-close");
	const backdrop = document.querySelectorAll(".navbar-backdrop");

	if (close.length) {
		for (var i = 0; i < close.length; i++) {
			close[i].addEventListener("click", function () {
				for (var j = 0; j < menu.length; j++) {
					menu[j].classList.toggle("hidden");
				}
			});
		}
	}

	if (backdrop.length) {
		for (var i = 0; i < backdrop.length; i++) {
			backdrop[i].addEventListener("click", function () {
				for (var j = 0; j < menu.length; j++) {
					menu[j].classList.toggle("hidden");
				}
			});
		}
	}
});
